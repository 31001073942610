// Object - page - top
/* Loading背景画面設定　*/
#splash {
    /*fixedで全面に固定*/
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #fff;
  text-align: center;
  top: 0;
  left: 0;
}

/* Loading画像中央配置　*/
#splash_logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include _mq-down(sm) {
  }
}

/* Loading アイコンの大きさ設定　*/
#splash_logo {
  width: _vw(600);
  @include _mq-up(xxl) {
    width: 600px;
  }
  @include _mq-down(sm) {
    width: 290px;
  }
}

/* fadeUpをするアイコンの動き */
.fadeUp {
animation-name: fadeUpAnime;
animation-duration: 1s;
animation-fill-mode: forwards;
opacity: 0;
}

@keyframes fadeUpAnime {
  from {
    opacity: 0;
    transform: translateY(100px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// ============================================
// mainvisual
// ============================================

.Inr {
    width: 0;
    height: 0;
    position: absolute;
}

.Container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.Container-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    height: 100%;
}

body:not(.fp-viewing-0):not(.fp-viewing-1) .mainvisual {
    display: none;
}

section.sec-Feature,
section.sec-Course,
section.sec-Support,
section.sec-Opencampus,
section.sec-HighSchool,
section.sec-YouTube,
//section.sec-Admission,
section.sec-Footer {
    background-color: #fff;
}

.swiper-slide {
    min-height: auto; //追記必要？
}

.mainvisual {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    //height: calc(var(--vh, 1vh) * 100);
    background: url("../../assets/img/home/bg_mainvisual_pc.jpg") center center / cover no-repeat;
    position: fixed;
    //z-index: 1;
    z-index: -1;
    @include _mq-down(md) {
        height: calc(var(--vh, 1vh) * 100);
    }
    @include _mq-down(sm) {
        background: url("../../assets/img/home/bg_mainvisual_sp.jpg") center bottom / cover no-repeat;
    }
    .Container {
        .text-01 {
            width: _vw(494, 1366);
            height: _vw(272, 1366);
            position: absolute;
            left: 0;
            right: 0;
            bottom: auto;
            transform: translate(-50%, -12.9%);
            @include _mq-up(xxl) {
                width: 494px;
                height: 272px;
            }
            @include _mq-down(sm) {
                position: absolute;
                width: 358px;
                height: 197px;
                transform: translate(-50%, 57.6%);
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }
    }
}

.sec-Feature {
    .slide-top {
        /*@include _mq-up(sm) {
            background: url("../../assets/img/home/bg_feature_pc.jpg") center center / cover no-repeat;
        }
        @include _mq-down(sm) {
            background: url("../../assets/img/home/bg_feature_sp.jpg") center center / cover no-repeat;
        }*/
        .Container {
            .inr {
                .text-feature-back {
                    display: block;
                    width: _vw(864);
                    height: _vw(475);
                    position: absolute;
                    z-index: 998;
                    transform: translate(-57.4%, -50%);
                    @include _mq-up(xxl) {
                        width: 864px;
                        height: 475px;
                    }
                    @include _mq-down(sm) {
                        width: 430px;
                        height: 237px;
                        transform: translate(-50%, -52%);
                    }
                }
                .text-feature {
                    display: block;
                    width: _vw(540);
                    max-width: 540px;
                    position: absolute;
                    z-index: 999;
                    transform: translate(-50%, -50%);
                    @include _mq-down(sm) {
                        width: 310px;
                        height: 142px;
                        transform: translate(-50%, -50%);
                    }

                }
            }
        }
    }
    .swiper {

    }
    .slide02 {
        @include _mq-up(sm) {
            background: url("../../assets/img/home/bg_feature-slide_02_pc.jpg") center center / cover no-repeat;
        }
        @include _mq-down(sm) {
            background: url("../../assets/img/home/bg_feature-slide_02_sp.jpg") center center / cover no-repeat;
        }
    }
    .slide03 {
        @include _mq-up(sm) {
            background: url("../../assets/img/home/bg_feature-slide_03_pc.jpg") center center / cover no-repeat;
        }
        @include _mq-down(sm) {
            background: url("../../assets/img/home/bg_feature-slide_03_sp.jpg") center center / cover no-repeat;
        }
    }
    .slide04 {
        @include _mq-up(sm) {
            background: url("../../assets/img/home/bg_feature-slide_04_pc.jpg") center center / cover no-repeat;
        }
        @include _mq-down(sm) {
            background: url("../../assets/img/home/bg_feature-slide_04_sp.jpg") center center / cover no-repeat;
        }
    }
    .slide05 {
        @include _mq-up(sm) {
            background: url("../../assets/img/home/bg_feature-slide_05_pc.jpg") center center / cover no-repeat;
        }
        @include _mq-down(sm) {
            background: url("../../assets/img/home/bg_feature-slide_05_sp.jpg") center center / cover no-repeat;
        }
    }
    .slide-wrap {
        .Container {
            //padding-top: _vw(60, 1366); //調整
            @include _mq-down(sm) {
                //padding-top: _vw(60, 375);
            }
        }
        .contents__box {
            width: _vw(860, 1366);
            height: _vw(355, 1366);
            background: rgba(255,255,255,0.8);
            position: relative;
            box-sizing: border-box;
            margin-top: _vw(60);
            padding-top: _vw(55);
            @include _mq-up(xxl) {
                width: 860px;
                height: 355px;
                padding: 60px;
                margin-top: 60px;
            }
            @include _mq-down(sm) {
                width: 300px;
                height: 360px;
                padding: 40px 25px 30px;
                margin-top: 60px;
            }
            &::before {
                content: '';
                width: _vw(154);
                height: _vw(53);
                background: url("../../assets/img/home/txt_pick-up-point.svg") center center / contain no-repeat;
                position: absolute;
                top: _vw(-86);
                left: 0;
                right: 0;
                margin: 0 auto;
                @include _mq-up(xxl) {
                    width: 154px;
                    height: 53px;
                    top: -86px;
                }
                @include _mq-down(sm) {
                    width: 100%;
                    height: 39px;
                    left: 50%;
                    transform: translateX(-50%);
                    top: -80px;
                }
            }
            & .num {
                display: inline-block;
                position: absolute;
                top: _vw(-35, 1366);
                left: 0;
                right: 0;
                margin: 0 auto;
                font-size: _vw(114, 1366);
                color: $color-brand;
                font-family: times;
                @include _mq-up(xxl) {
                    top: -35px;
                    font-size: 114px;
                }
                @include _mq-down(sm) {
                    font-size: 105px;
                    top: -45px;
                }
            }
            h3.tit {
                display: block;
                margin-top: _vw(35);
                margin-bottom: _vw(30);
                height: _vw(39);
                &.tit-01 {
                    img {
                        display: block;
                        height: 100%;
                    }
                }
                &.tit-02 {
                    .u-d-block {
                        height: 100%;
                    }
                    img {
                        display: block;
                        height: 100%;
                    }
                }
                @include _mq-up(xxl) {
                    display: block;
                    margin-top: 35px;
                    margin-bottom: 30px;
                    height: 39px;
                }
                @include _mq-down(sm) {
                    width: 100%;
                    height: auto;
                    display: block;
                    margin-top: 25px;
                    margin-bottom: 25px;
                    &.tit-01 {
                        display: block;
                        width: 100%;
                        height: 20px;
                    }
                    &.tit-02 {
                        display: block;
                        width: 100%;
                        height: 54px;
                    }
                }
            }
            & .lead {
                &.text {
                    font-size: _vw(18);
                    line-height: (34 / 18);
                    letter-spacing: 0.1em;
                    @include _mq-up(xxl) {
                        font-size: 18px;
                    }
                    @include _mq-down(sm) {
                        font-size: 12px;
                        line-height: 2;
                        letter-spacing: 0.05em;
                        text-align: justify;
                    }
                }
            }
        }
    }
}

/**
* リンクボタン
**/
.btn-center {
    position: absolute;
    left: 0;
    right: 0;
    bottom: _vw(-29, 1366);
    display: inline-block;
    letter-spacing: 0.1em;
    padding-left: 0.1em;
    @include _mq-up(xxl) {
        bottom: -29px;
    }
    @include _mq-down(sm) {
        bottom: _vw(-25, 375);
    }
    a {
        font-size: _vw(14, 1366);
        display: inline-block;
        //background: $color-brand;
        //background: linear-gradient(90deg, #00c6fb 0%, #005bca 100%);
        width: _vw(250, 1366);
        height: _vw(50, 1366);
        padding: _vw(23, 1366) _vw(10, 1366);
        box-sizing: border-box;
        //padding: 1.6em 5.3em;
        @include _mq-up(xxl) {
            font-size: 14px;
            width: 250px;
            height: 50px;
            padding: 23px 10px;
        }
        @include _mq-down(sm) {
            font-size: _vw(12, 375);
            width: _vw(200, 375);
            height: _vw(50, 375);
            padding: _vw(19, 375) _vw(10, 375);
        }
        &.c-gradation {
            color: #fff;
            background: $color-brand;
            background: linear-gradient(135deg, $color-brand-accent 0%, $color-brand 100%);
        }
    }
}

.btn-white {
    position: absolute;
    left: 0;
    right: 0;
    bottom: _vw(-29, 1366);
    display: inline-block;
    @include _mq-up(xxl) {
        bottom: -29px;
    }
    @include _mq-down(sm) {
        bottom: _vw(-25, 375);
    }
}

.top-btn-column {
    position: absolute;
    bottom: _vw(-25);
    left: 0;
    right: 0;
    margin: 0 auto;
    .top-btn-box {
        height: _vw(50);
        padding: _vw(15);
    }
    @include _mq-up(xxl) {
        bottom: -25px;
        .top-btn-box {
            height: 50px;
            padding: 15px;
        }
    }
    @include _mq-down(sm) {
        display: flex;
        flex-flow: column;
        width: 215px;
        bottom: -25px;
        .top-btn-box {
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            width: 100%;
            height: 50px;
            letter-spacing: 0.1em;
        }
        .top-btn-box + .top-btn-box {
            margin-top: 15px;
            margin-left: 0;
        }
    }
}

.swiper-slide {

}

.text-btn-line {
    &.subject {
        margin-top: _vw(55);
    }
    &.student {
        margin-top: _vw(50);
    }
    a {
        color: #fff;
        font-size: _vw(16);
        letter-spacing: 0.1em;
        border-bottom: 1px solid #fff;
        padding: _vw(4) _vw(2) _vw(4) _vw(4);
    }
    svg {
        width: _vw(8);
        height: _vw(12);
        margin-right: _vw(10);
    }
    .triangle-arrow {
        fill: #fff;
    }
    @include _mq-up(xxl) {
        &.subject {
            margin-top: 55px;
        }
        &.student {
            margin-top: 50px;
        }
        a {
            font-size: 16px;
            border-bottom: 1px solid #fff;
            padding: 4px 2px 4px 4px;
        }
        svg {
            width: 8px;
            height: 12px;
            margin-right: 10px;
        }
    }
    @include _mq-down(sm) {
        &.subject {
            margin-top: 10px;
        }
        &.student {
            margin-top: 30px;
        }
        a {
            font-size: 16px;
            border-bottom: 1px solid #fff;
            padding: 4px 2px 4px 4px;
        }
        svg {
            width: 8px;
            height: 12px;
            margin-right: 10px;
        }
    }
}

.sec-Course {
        .Container {
            width: 100%;
            @include _mq-down(sm) {
                flex-flow: column;
                //padding-top: 60px;
            }
        }
        .course {
            width: 50%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            &.course-h {
                background: url("../../assets/img/home/image_course-h_pc.jpg") center center / cover;
                //height: 100vh;
                @include _mq-up(xxl) {

                }
                @include _mq-down(sm) {
                    width: 100vw;
                    padding-top: 60px;
                    height: 54.7226%;
                    box-sizing: border-box;
                    background: url("../../assets/img/home/image_course-h_sp.jpg") center center / cover;
                }
                & > .text-bg {
                    width: 100%;
                    width: _vw(683);
                    height: _vw(250);
                    @include _mq-up(xxl) {
                        width: 683px;
                        height: 250px;
                    }
                    @include _mq-down(sm) {
                        width: 424px;
                        height: 149px;
                        display: flex;
                        align-items: center;
                    }
                }
            }
            &.course-b {
                background: url("../../assets/img/home/image_course-b_pc.jpg") center center / cover;
                //height: 100vh;
                //padding-top: _vw(30, 1366);
                @include _mq-down(sm) {
                    width: 100%;
                    //height: calc(50vh - 60px); 必要？
                    height: 45.2774%;
                    //padding-bottom: 30px;
                    box-sizing: border-box;
                    background: url("../../assets/img/home/image_course-b_sp.jpg") center center / cover;
                }
                & > .text-bg {
                    width: 100%;
                    width: _vw(683);
                    height: _vw(250);
                    @include _mq-up(xxl) {
                        width: 683px;
                        height: 250px;
                    }
                    @include _mq-down(sm) {
                        width: 426px;
                        height: 150px;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
}

.sec-Support {
    text-align: center;
    @include _mq-up(sm) {
        background: url("../../assets/img/home/bg_support_pc.jpg") center center / cover no-repeat;
    }
    @include _mq-down(sm) {
        background: url("../../assets/img/home/bg_support_sp.jpg") center center / cover no-repeat;
    }
    .contents__box {
        transform: translate(0, 7.8%);
    }
    .text-tit {
        width: _vw(579);
        height: _vw(255);
        @include _mq-up(xxl) {
            width: 579px;
            height: 255px;
        }
        @include _mq-down(sm) {
            width: 317px;
            height: 140px;
        }
    }
    .btn-box-support {
        margin-top: _vw(78);
        @include _mq-up(xxl) {
            margin-top: 78px;
        }
        @include _mq-down(sm) {
            margin-top: 37px;
        }
    }
}

.sec-Opencampus {
    @include _mq-up(sm) {
        background: url("../../assets/img/home/bg_opencampus_pc.jpg") center center / cover no-repeat;
    }
    @include _mq-down(sm) {
        background: url("../../assets/img/home/bg_opencampus_sp.jpg") center center / cover no-repeat;
    }
    .contents__box {
        @include _mq-down(sm) {
            padding-top: 60px;
        }
        .tit {
            position: relative;
        }
        .text {
            width: _vw(924);
            height: _vw(356);
            display: block;
            @include _mq-up(xxl) {
                width: 924px;
                height: 356px;
            }
            @include _mq-down(sm) {
                width: 362px;
                height: 367px;
            }
        }
        .image {
            width: _vw(210);
            height: _vw(82);
            position: absolute;
            top: _vw(78);
            left: 0;
            right: 0;
            margin: 0 auto;
            @include _mq-up(xxl) {
                width: 210px;
                height: 82px;
                top: 78px;
            }
            @include _mq-down(sm) {
                width: 134px;
                height: 51px;
                top: 86px;
            }
        }
    }
    .btn-box-oc {
        display: flex;
        justify-content: center;
        margin-top: _vw(35, 1366);
        @include _mq-up(xxl) {
            margin-top: 35px;
        }
        @include _mq-down(sm) {
            margin-top: 20px;
        }
    }
    .oc-btn {
        display: block;
        width: _vw(280);
        height: _vw(70);
        text-align: center;
        font-size: _vw(16);
        font-weight: 700;
        position: relative;
        padding-top: _vw(18);
        border: 2px solid #000;
        background: #fbed37;
        &::after {
            position: absolute;
            bottom: _vw(16);
            right: 0;
            left: 0;
            margin: 0 auto;
            content: '';
            background: url("../../assets/img/home/oc_btn_arrow.svg") center center / cover;
            width: _vw(29);
            height: _vw(6);
        }
        &.choi {
            margin-left: _vw(30);
        }
        @include _mq-up(xxl) {
            width: 280px;
            height: 70px;
            font-size: 16px;
            padding-top: 18px;
            &::after {
                bottom: 16px;
                width: 29px;
                height: 6px;
            }
            &.choi {
                margin-left: 30px;
            }
        }
        @include _mq-down(md) {
            width: auto;
            min-width: _vw(280);
            padding-right: _vw(10);
            padding-left: _vw(10);
        }
        @include _mq-down(sm) {
            width: 163px;
            height: 85px;
            font-size: 12px;
            line-height: (20/12);
            padding: 15px;
            &::after {
                bottom: 10px;
                width: 29px;
                height: 6px;
            }
            &.choi {
                margin-left: 8px;
            }
        }
    }
}

.sec-HighSchool {
    .Container {
        @include _mq-down(sm) {
            flex-flow: column;
        }
        .image {
            width: 50%;
            height: 100vh;
            display: block;
            img {
                width: 100%;
                height: 100%;
            }
            @include _mq-down(sm) {
                width: 100%;
                height: 54.7226%;
            }
        }
        .content {
            width: 50%;
            height: 100vh;
            background: $color-brand;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            position: relative;
            @include _mq-down(sm) {
                width: 100%;
                height: 45.2774%;
                padding-bottom: 18px;
            }
            &::after {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 0 _vw(30) _vw(30);
                border-color: transparent transparent rgba(0,0,0,0.2) transparent;
                position: absolute;
                right: _vw(15);
                bottom: _vw(15);
                @include _mq-up(xxl) {
                    border-width: 0 0 30px 30px;
                    right: 15px;
                    bottom: 15px;
                }
                @include _mq-down(sm) {
                    border-width: 0 0 30px 30px;
                    right: 15px;
                    bottom: 15px;
                }
            }
            h3 {
                color: #fff;
                font-size: _vw(52);
                line-height: _vw(80);
                font-weight: 700;
                text-align: center;
                letter-spacing: 0.1em;
                @include _mq-up(xxl) {
                    font-size: 52px;
                    line-height: 80px;
                }
                @include _mq-down(sm) {
                    font-size: 30px;
                    line-height: 40px;
                }
            }
            .text {
                color: #fff;
                font-size: _vw(18);
                text-align: center;
                letter-spacing: 0.1em;
                margin-top: _vw(25);
                @include _mq-up(xxl) {
                    font-size: 18px;
                    margin-top: 25px;
                }
                @include _mq-down(sm) {
                    font-size: 12px;
                    margin-top: 12px;
                }
            }
            .icon-hat {
                width: _vw(128);
                height: _vw(117);
                margin-bottom: _vw(30);
                @include _mq-up(xxl) {
                    width: 128px;
                    height: 117px;
                    margin-bottom: 30px;
                }
                @include _mq-down(sm) {
                    width: 56px;
                    height: 51px;
                    margin-bottom: 18px;
                }
            }
        }
    }
}

.sec-YouTube {
    .contents__box {
        .inr {
            text-align: center;
            width: _vw(1130);
            height: _vw(571);
            display: flex;
            padding-top: _vw(30);
            @include _mq-up(xxl) {
                width: 1130px;
                height: 571px;
                padding-top: 30px;
            }
            @include _mq-down(sm) {
                width: 100vw;
                height: auto;
                flex-flow: column;
                justify-content: center;
                padding-top: 0;
            }
        }
        .left {
            width: _vw(530);
            height: 100%;
            @include _mq-up(xxl) {
                width: 530px;
            }
            @include _mq-down(sm) {
                width: 215px;
                height: 232px;
                margin: 0 auto;
            }
        }
        .right {
            width: _vw(600);
            height: 100%;
            display: flex;
            align-items: center;
            flex-flow: column;
            justify-content: center;
            position: relative;
            &::before {
                content: '';
                width: _vw(594) _vw(288);
                background: url("../../assets/img/home/bgtxt_movie.svg") top 33% center / contain no-repeat;
                background-size: _vw(594) _vw(288);
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
            }
            @include _mq-up(xxl) {
                width: 600px;
                &::before {
                    width: 594px 288px;
                    background-size: 594px 288px;
                }
            }
            @include _mq-down(sm) {
                padding-bottom: 0;
                width: 100%;
                height: 248px;
                padding-bottom: 0;
                margin-top: 17px;
                &::before {
                    width: 391px;
                    height: 190px;
                    background-size: 391px 190px;
                    background: url("../../assets/img/home/bgtxt_movie.svg") center center / contain no-repeat;
                    top: -1.7%;
                    right: auto;
                    bottom: auto;
                    left: auto;
                    margin: auto;
                }
            }
        }
        .icon-yt {
            width: _vw(80);
            height: _vw(57);
            margin-bottom: _vw(35);
            z-index: 1;
            @include _mq-up(xxl) {
                width: 80px;
                height: 57px;
                margin-bottom: 35px;
            }
            @include _mq-down(sm) {
                width: 38px;
                height: 27px;
                margin-bottom: 8px;
            }
        }
        .tit {
            font-size: _vw(52);
            font-weight: bold;
            letter-spacing: 0.15em;
            position: relative;
            padding-bottom: _vw(16);
            margin-bottom: _vw(25);
            line-height: _vw(80);
            &::after {
                content: '';
                width: _vw(33);
                height: _vw(3);
                background: $color-brand;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
            @include _mq-up(xxl) {
                font-size: 52px;
                padding-bottom: 16px;
                margin-bottom: 25px;
                line-height: 80px;
                &::after {
                    width: 33px;
                    height: 3px;
                }
            }
            @include _mq-down(sm) {
                font-size: 30px;
                line-height: 40px;
                padding-bottom: 8px;
                margin-bottom: 6px;
                &::after {
                    width: 20px;
                    height: 2px;
                }
            }
        }
        .text {
            font-size: _vw(18);
            line-height: (34/18);
            letter-spacing: 0.1em;
            @include _mq-up(xxl) {
                font-size: 16px;
            }
            @include _mq-down(sm) {
                font-size: 12px;
            }
        }
        .btn-box-youtube {
            margin-top: _vw(50);
            @include _mq-up(xxl) {
                margin-top: 50px;
            }
            @include _mq-down(sm) {
                margin-top: 20px;
            }
        }
    }
}

.sec-Admission {
    .top-wrap {
        background-color: #004182;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            background: url("../../assets/img/home/bg_admission_pc.svg") center center / contain;
            width: 100%;
            height: _vw(381);
            @include _mq-down(sm) {
                height: _vw(335, 375); //固定値はダメ。_vw必要。
                min-height: 335px;
                background: url("../../assets/img/home/bg_admission_sp.svg") center center / cover;
            }
        }
        .content {
            position: relative;
            @include _mq-down(sm) {
                padding-top: 32px; //縦センター調整（ヘッダー分+@）
            }
            .tit {
                display: block;
            }
            img {
                width: _vw(540);
                height: _vw(215);
                @include _mq-up(xxl) {
                    width: 540px;
                    height: 215px;
                }
                @include _mq-down(sm) {
                    width: 282px;
                    height: 90px;
                }
            }
            .text {
                font-size: _vw(18);
                letter-spacing: 0.1em;
                color: #fff;
                margin-top: _vw(30);
                @include _mq-up(xxl) {
                    font-size: 18px;
                    margin-top: 30px;
                }
                @include _mq-down(sm) {
                    font-size: 12px;
                    margin-top: 10px;
                    line-height: 2;
                }
            }
        }
    }
    .slide-wrap {
        background: url("../../assets/img/home/bg_admission_slide_pc.jpg") center center / cover;
        @include _mq-down(sm) {
            background: url("../../assets/img/home/bg_admission_slide_sp.jpg") center center / cover;
        }
        .Container {
            @include _mq-down(sm) {
                padding-top: _vw(30, 375);
            }
        }
        .contents__box {
            width: _vw(840);
            height: _vw(360);
            position: relative;
            background-color: #fff;
            box-sizing: border-box;
            padding: _vw(50) _vw(50) _vw(65) _vw(65);
            display: flex;
            justify-content: space-between;
            &::before {
                content: '';
                width: _vw(433);
                height: _vw(76);
                background: #ccc;
                position: absolute;
                top: _vw(-98);
                left: 0;
                right: 0;
                margin: 0 auto;
            }
            &.box-01::before {
                background: url("../../assets/img/home/txt_admission_box_01.svg");
            }
            &.box-02::before {
                background: url("../../assets/img/home/txt_admission_box_02.svg");
            }
            &.box-03::before {
                background: url("../../assets/img/home/txt_admission_box_03.svg");
            }
            &.box-04::before {
                background: url("../../assets/img/home/txt_admission_box_04.svg");
            }
            &.box-05::before {
                background: url("../../assets/img/home/txt_admission_box_05.svg");
            }
            &.box-06::before {
                background: url("../../assets/img/home/txt_admission_box_06.svg");
            }
            @include _mq-up(xxl) {
                width: 840px;
                height: 360px;
                padding: 50px 50px 65px 65px;
                &::before {
                    width: 433px;
                    height: 76px;
                    top: -98px;
                }
            }
            @include _mq-down(sm) {
                width: 290px;
                height: 390px;
                padding: 40px 30px 65px 30px;
                top: _vh(25, 667);
                &::before {
                    width: 264px;
                    height: 45px;
                    top: -63px;
                }
            }
            @include _mq-down(sm) {
                .inr {
                    width: 100%;
                }
            }
            .subhead {
                color: $color-brand;
                font-size: _vw(30);
                line-height: 1;
                font-weight: bold;
                letter-spacing: 0.1em;
                text-align: left;
                @include _mq-up(xxl) {
                    font-size: 30px;
                }
                @include _mq-down(sm) {
                    font-size: 22px;
                    line-height: 37px;
                    //min-height: 74px;
                    min-height: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                }
            }
            .list {
                width: _vw(255);
                margin-top: _vw(24);
                @include _mq-up(xxl) {
                    width: 255px;
                    margin-top: 24px;
                }
                @include _mq-down(sm) {
                    width: 100%;
                    margin-top: 10px;
                }
                li {
                    border-bottom: 1px solid #dbdbdb;
                    font-size: _vw(16);
                    text-align: left;
                    letter-spacing: 0.1em;
                    line-height: 1;
                    padding-top: _vw(17, 1366);
                    padding-bottom: _vw(17, 1366);
                    padding-left: 1.2em; //デフォルトは1.1em
                    /*@include _mq-down(sm) {
                        height: _vw(50, 375);
                        font-size: _vw(15, 375);
                        padding-top: _vw(17, 375);
                        padding-bottom: _vw(17, 375);
                    }*/
                    @include _mq-up(xxl) {
                        font-size: 16px;
                        padding-top: 17px;
                        padding-bottom: 17px;
                    }
                    @include _mq-down(sm) {
                        height: auto;
                        font-size: 16px;
                        padding-top: 17px;
                        padding-bottom: 17px;
                    }
                }
            }
            .etc {
                font-size: _vw(16);
                padding-top: _vw(17);
                text-align: left;
                @include _mq-up(xxl) {
                    font-size: 16px;
                    padding-top: 17px;
                }
                @include _mq-down(sm) {
                    font-size: 16px;
                    padding-top: 17px;
                }
            }
            .image {
                width: _vw(314);
                height:  _vw(239);
                @include _mq-up(xxl) {
                    width: 314px;
                    height: 239px;
                }
                @include _mq-down(sm) {
                }
            }
        }
        &.slide02 {
            .contents__box {
                flex-flow: column;
                align-items: center;
                padding: _vw(50) _vw(40) _vw(65) _vw(40);
                @include _mq-up(xxl) {
                    padding: 50px 40px 65px 40px;
                }
                @include _mq-down(sm) {
                    padding: 40px 30px 65px 30px;
                }
                .subhead {
                    text-align: center;
                }
                .image {
                    width: _vw(660);
                    height: _vw(154);
                    @include _mq-up(xxl) {
                        width: 660px;
                        height: 154px;
                    }
                    @include _mq-down(sm) {
                        width: 200px;
                        height: 169px;
                        margin-top: 12px;
                    }
                }
                .box-text {
                    font-size: _vw(18);
                    letter-spacing: 0.05em;
                    @include _mq-up(xxl) {
                        font-size: 18px;
                    }
                    @include _mq-down(sm) {
                        font-size: 12px;
                        letter-spacing: 0.1em;
                        line-height: 2;
                        margin-top: 14px;
                    }
                }
            }
        }
    }
}
