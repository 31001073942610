@charset "UTF-8";
/*normal*/
/* Loading背景画面設定　*/
#splash {
  /*fixedで全面に固定*/
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #fff;
  text-align: center;
  top: 0;
  left: 0; }

/* Loading画像中央配置　*/
#splash_logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

/* Loading アイコンの大きさ設定　*/
#splash_logo {
  width: 43.92387vw; }
  @media screen and (min-width: 1366px) {
    #splash_logo {
      width: 600px; } }
  @media screen and (max-width: 499px) {
    #splash_logo {
      width: 290px; } }

/* fadeUpをするアイコンの動き */
.fadeUp {
  animation-name: fadeUpAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0; }

@keyframes fadeUpAnime {
  from {
    opacity: 0;
    transform: translateY(100px); }
  to {
    opacity: 1;
    transform: translateY(0); } }

.Inr {
  width: 0;
  height: 0;
  position: absolute; }

.Container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; }

.Container-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  height: 100%; }

body:not(.fp-viewing-0):not(.fp-viewing-1) .mainvisual {
  display: none; }

section.sec-Feature,
section.sec-Course,
section.sec-Support,
section.sec-Opencampus,
section.sec-HighSchool,
section.sec-YouTube,
section.sec-Footer {
  background-color: #fff; }

.swiper-slide {
  min-height: auto; }

.mainvisual {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: url("../../assets/img/home/bg_mainvisual_pc.jpg") center center/cover no-repeat;
  position: fixed;
  z-index: -1; }
  @media screen and (max-width: 767px) {
    .mainvisual {
      height: calc(var(--vh, 1vh) * 100); } }
  @media screen and (max-width: 499px) {
    .mainvisual {
      background: url("../../assets/img/home/bg_mainvisual_sp.jpg") center bottom/cover no-repeat; } }
  .mainvisual .Container .text-01 {
    width: 36.16398vw;
    height: 19.91215vw;
    position: absolute;
    left: 0;
    right: 0;
    bottom: auto;
    transform: translate(-50%, -12.9%); }
    @media screen and (min-width: 1366px) {
      .mainvisual .Container .text-01 {
        width: 494px;
        height: 272px; } }
    @media screen and (max-width: 499px) {
      .mainvisual .Container .text-01 {
        position: absolute;
        width: 358px;
        height: 197px;
        transform: translate(-50%, 57.6%);
        top: 0;
        bottom: 0;
        margin: auto; } }

.sec-Feature .slide-top {
  /*@include _mq-up(sm) {
            background: url("../../assets/img/home/bg_feature_pc.jpg") center center / cover no-repeat;
        }
        @include _mq-down(sm) {
            background: url("../../assets/img/home/bg_feature_sp.jpg") center center / cover no-repeat;
        }*/ }
  .sec-Feature .slide-top .Container .inr .text-feature-back {
    display: block;
    width: 63.25037vw;
    height: 34.77306vw;
    position: absolute;
    z-index: 998;
    transform: translate(-57.4%, -50%); }
    @media screen and (min-width: 1366px) {
      .sec-Feature .slide-top .Container .inr .text-feature-back {
        width: 864px;
        height: 475px; } }
    @media screen and (max-width: 499px) {
      .sec-Feature .slide-top .Container .inr .text-feature-back {
        width: 430px;
        height: 237px;
        transform: translate(-50%, -52%); } }
  .sec-Feature .slide-top .Container .inr .text-feature {
    display: block;
    width: 39.53148vw;
    max-width: 540px;
    position: absolute;
    z-index: 999;
    transform: translate(-50%, -50%); }
    @media screen and (max-width: 499px) {
      .sec-Feature .slide-top .Container .inr .text-feature {
        width: 310px;
        height: 142px;
        transform: translate(-50%, -50%); } }

@media screen and (min-width: 500px) {
  .sec-Feature .slide02 {
    background: url("../../assets/img/home/bg_feature-slide_02_pc.jpg") center center/cover no-repeat; } }

@media screen and (max-width: 499px) {
  .sec-Feature .slide02 {
    background: url("../../assets/img/home/bg_feature-slide_02_sp.jpg") center center/cover no-repeat; } }

@media screen and (min-width: 500px) {
  .sec-Feature .slide03 {
    background: url("../../assets/img/home/bg_feature-slide_03_pc.jpg") center center/cover no-repeat; } }

@media screen and (max-width: 499px) {
  .sec-Feature .slide03 {
    background: url("../../assets/img/home/bg_feature-slide_03_sp.jpg") center center/cover no-repeat; } }

@media screen and (min-width: 500px) {
  .sec-Feature .slide04 {
    background: url("../../assets/img/home/bg_feature-slide_04_pc.jpg") center center/cover no-repeat; } }

@media screen and (max-width: 499px) {
  .sec-Feature .slide04 {
    background: url("../../assets/img/home/bg_feature-slide_04_sp.jpg") center center/cover no-repeat; } }

@media screen and (min-width: 500px) {
  .sec-Feature .slide05 {
    background: url("../../assets/img/home/bg_feature-slide_05_pc.jpg") center center/cover no-repeat; } }

@media screen and (max-width: 499px) {
  .sec-Feature .slide05 {
    background: url("../../assets/img/home/bg_feature-slide_05_sp.jpg") center center/cover no-repeat; } }

.sec-Feature .slide-wrap .contents__box {
  width: 62.95754vw;
  height: 25.98829vw;
  background: rgba(255, 255, 255, 0.8);
  position: relative;
  box-sizing: border-box;
  margin-top: 4.39239vw;
  padding-top: 4.02635vw; }
  @media screen and (min-width: 1366px) {
    .sec-Feature .slide-wrap .contents__box {
      width: 860px;
      height: 355px;
      padding: 60px;
      margin-top: 60px; } }
  @media screen and (max-width: 499px) {
    .sec-Feature .slide-wrap .contents__box {
      width: 300px;
      height: 360px;
      padding: 40px 25px 30px;
      margin-top: 60px; } }
  .sec-Feature .slide-wrap .contents__box::before {
    content: '';
    width: 11.27379vw;
    height: 3.87994vw;
    background: url("../../assets/img/home/txt_pick-up-point.svg") center center/contain no-repeat;
    position: absolute;
    top: -6.29575vw;
    left: 0;
    right: 0;
    margin: 0 auto; }
    @media screen and (min-width: 1366px) {
      .sec-Feature .slide-wrap .contents__box::before {
        width: 154px;
        height: 53px;
        top: -86px; } }
    @media screen and (max-width: 499px) {
      .sec-Feature .slide-wrap .contents__box::before {
        width: 100%;
        height: 39px;
        left: 50%;
        transform: translateX(-50%);
        top: -80px; } }
  .sec-Feature .slide-wrap .contents__box .num {
    display: inline-block;
    position: absolute;
    top: -2.56223vw;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-size: 8.34553vw;
    color: #036EB8;
    font-family: times; }
    @media screen and (min-width: 1366px) {
      .sec-Feature .slide-wrap .contents__box .num {
        top: -35px;
        font-size: 114px; } }
    @media screen and (max-width: 499px) {
      .sec-Feature .slide-wrap .contents__box .num {
        font-size: 105px;
        top: -45px; } }
  .sec-Feature .slide-wrap .contents__box h3.tit {
    display: block;
    margin-top: 2.56223vw;
    margin-bottom: 2.19619vw;
    height: 2.85505vw; }
    .sec-Feature .slide-wrap .contents__box h3.tit.tit-01 img {
      display: block;
      height: 100%; }
    .sec-Feature .slide-wrap .contents__box h3.tit.tit-02 .u-d-block {
      height: 100%; }
    .sec-Feature .slide-wrap .contents__box h3.tit.tit-02 img {
      display: block;
      height: 100%; }
    @media screen and (min-width: 1366px) {
      .sec-Feature .slide-wrap .contents__box h3.tit {
        display: block;
        margin-top: 35px;
        margin-bottom: 30px;
        height: 39px; } }
    @media screen and (max-width: 499px) {
      .sec-Feature .slide-wrap .contents__box h3.tit {
        width: 100%;
        height: auto;
        display: block;
        margin-top: 25px;
        margin-bottom: 25px; }
        .sec-Feature .slide-wrap .contents__box h3.tit.tit-01 {
          display: block;
          width: 100%;
          height: 20px; }
        .sec-Feature .slide-wrap .contents__box h3.tit.tit-02 {
          display: block;
          width: 100%;
          height: 54px; } }
  .sec-Feature .slide-wrap .contents__box .lead.text {
    font-size: 1.31772vw;
    line-height: 1.88889;
    letter-spacing: 0.1em; }
    @media screen and (min-width: 1366px) {
      .sec-Feature .slide-wrap .contents__box .lead.text {
        font-size: 18px; } }
    @media screen and (max-width: 499px) {
      .sec-Feature .slide-wrap .contents__box .lead.text {
        font-size: 12px;
        line-height: 2;
        letter-spacing: 0.05em;
        text-align: justify; } }

/**
* リンクボタン
**/
.btn-center {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2.12299vw;
  display: inline-block;
  letter-spacing: 0.1em;
  padding-left: 0.1em; }
  @media screen and (min-width: 1366px) {
    .btn-center {
      bottom: -29px; } }
  @media screen and (max-width: 499px) {
    .btn-center {
      bottom: -6.66667vw; } }
  .btn-center a {
    font-size: 1.02489vw;
    display: inline-block;
    width: 18.30161vw;
    height: 3.66032vw;
    padding: 1.68375vw 0.73206vw;
    box-sizing: border-box; }
    @media screen and (min-width: 1366px) {
      .btn-center a {
        font-size: 14px;
        width: 250px;
        height: 50px;
        padding: 23px 10px; } }
    @media screen and (max-width: 499px) {
      .btn-center a {
        font-size: 3.2vw;
        width: 53.33333vw;
        height: 13.33333vw;
        padding: 5.06667vw 2.66667vw; } }
    .btn-center a.c-gradation {
      color: #fff;
      background: #036EB8;
      background: linear-gradient(135deg, #004182 0%, #036EB8 100%); }

.btn-white {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2.12299vw;
  display: inline-block; }
  @media screen and (min-width: 1366px) {
    .btn-white {
      bottom: -29px; } }
  @media screen and (max-width: 499px) {
    .btn-white {
      bottom: -6.66667vw; } }

.top-btn-column {
  position: absolute;
  bottom: -1.83016vw;
  left: 0;
  right: 0;
  margin: 0 auto; }
  .top-btn-column .top-btn-box {
    height: 3.66032vw;
    padding: 1.0981vw; }
  @media screen and (min-width: 1366px) {
    .top-btn-column {
      bottom: -25px; }
      .top-btn-column .top-btn-box {
        height: 50px;
        padding: 15px; } }
  @media screen and (max-width: 499px) {
    .top-btn-column {
      display: flex;
      flex-flow: column;
      width: 215px;
      bottom: -25px; }
      .top-btn-column .top-btn-box {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        width: 100%;
        height: 50px;
        letter-spacing: 0.1em; }
      .top-btn-column .top-btn-box + .top-btn-box {
        margin-top: 15px;
        margin-left: 0; } }

.text-btn-line.subject {
  margin-top: 4.02635vw; }

.text-btn-line.student {
  margin-top: 3.66032vw; }

.text-btn-line a {
  color: #fff;
  font-size: 1.1713vw;
  letter-spacing: 0.1em;
  border-bottom: 1px solid #fff;
  padding: 0.29283vw 0.14641vw 0.29283vw 0.29283vw; }

.text-btn-line svg {
  width: 0.58565vw;
  height: 0.87848vw;
  margin-right: 0.73206vw; }

.text-btn-line .triangle-arrow {
  fill: #fff; }

@media screen and (min-width: 1366px) {
  .text-btn-line.subject {
    margin-top: 55px; }
  .text-btn-line.student {
    margin-top: 50px; }
  .text-btn-line a {
    font-size: 16px;
    border-bottom: 1px solid #fff;
    padding: 4px 2px 4px 4px; }
  .text-btn-line svg {
    width: 8px;
    height: 12px;
    margin-right: 10px; } }

@media screen and (max-width: 499px) {
  .text-btn-line.subject {
    margin-top: 10px; }
  .text-btn-line.student {
    margin-top: 30px; }
  .text-btn-line a {
    font-size: 16px;
    border-bottom: 1px solid #fff;
    padding: 4px 2px 4px 4px; }
  .text-btn-line svg {
    width: 8px;
    height: 12px;
    margin-right: 10px; } }

.sec-Course .Container {
  width: 100%; }
  @media screen and (max-width: 499px) {
    .sec-Course .Container {
      flex-flow: column; } }

.sec-Course .course {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column; }
  .sec-Course .course.course-h {
    background: url("../../assets/img/home/image_course-h_pc.jpg") center center/cover; }
    @media screen and (max-width: 499px) {
      .sec-Course .course.course-h {
        width: 100vw;
        padding-top: 60px;
        height: 54.7226%;
        box-sizing: border-box;
        background: url("../../assets/img/home/image_course-h_sp.jpg") center center/cover; } }
    .sec-Course .course.course-h > .text-bg {
      width: 100%;
      width: 50vw;
      height: 18.30161vw; }
      @media screen and (min-width: 1366px) {
        .sec-Course .course.course-h > .text-bg {
          width: 683px;
          height: 250px; } }
      @media screen and (max-width: 499px) {
        .sec-Course .course.course-h > .text-bg {
          width: 424px;
          height: 149px;
          display: flex;
          align-items: center; } }
  .sec-Course .course.course-b {
    background: url("../../assets/img/home/image_course-b_pc.jpg") center center/cover; }
    @media screen and (max-width: 499px) {
      .sec-Course .course.course-b {
        width: 100%;
        height: 45.2774%;
        box-sizing: border-box;
        background: url("../../assets/img/home/image_course-b_sp.jpg") center center/cover; } }
    .sec-Course .course.course-b > .text-bg {
      width: 100%;
      width: 50vw;
      height: 18.30161vw; }
      @media screen and (min-width: 1366px) {
        .sec-Course .course.course-b > .text-bg {
          width: 683px;
          height: 250px; } }
      @media screen and (max-width: 499px) {
        .sec-Course .course.course-b > .text-bg {
          width: 426px;
          height: 150px;
          display: flex;
          align-items: center; } }

.sec-Support {
  text-align: center; }
  @media screen and (min-width: 500px) {
    .sec-Support {
      background: url("../../assets/img/home/bg_support_pc.jpg") center center/cover no-repeat; } }
  @media screen and (max-width: 499px) {
    .sec-Support {
      background: url("../../assets/img/home/bg_support_sp.jpg") center center/cover no-repeat; } }
  .sec-Support .contents__box {
    transform: translate(0, 7.8%); }
  .sec-Support .text-tit {
    width: 42.38653vw;
    height: 18.66764vw; }
    @media screen and (min-width: 1366px) {
      .sec-Support .text-tit {
        width: 579px;
        height: 255px; } }
    @media screen and (max-width: 499px) {
      .sec-Support .text-tit {
        width: 317px;
        height: 140px; } }
  .sec-Support .btn-box-support {
    margin-top: 5.7101vw; }
    @media screen and (min-width: 1366px) {
      .sec-Support .btn-box-support {
        margin-top: 78px; } }
    @media screen and (max-width: 499px) {
      .sec-Support .btn-box-support {
        margin-top: 37px; } }

@media screen and (min-width: 500px) {
  .sec-Opencampus {
    background: url("../../assets/img/home/bg_opencampus_pc.jpg") center center/cover no-repeat; } }

@media screen and (max-width: 499px) {
  .sec-Opencampus {
    background: url("../../assets/img/home/bg_opencampus_sp.jpg") center center/cover no-repeat; } }

@media screen and (max-width: 499px) {
  .sec-Opencampus .contents__box {
    padding-top: 60px; } }

.sec-Opencampus .contents__box .tit {
  position: relative; }

.sec-Opencampus .contents__box .text {
  width: 67.64275vw;
  height: 26.06149vw;
  display: block; }
  @media screen and (min-width: 1366px) {
    .sec-Opencampus .contents__box .text {
      width: 924px;
      height: 356px; } }
  @media screen and (max-width: 499px) {
    .sec-Opencampus .contents__box .text {
      width: 362px;
      height: 367px; } }

.sec-Opencampus .contents__box .image {
  width: 15.37335vw;
  height: 6.00293vw;
  position: absolute;
  top: 5.7101vw;
  left: 0;
  right: 0;
  margin: 0 auto; }
  @media screen and (min-width: 1366px) {
    .sec-Opencampus .contents__box .image {
      width: 210px;
      height: 82px;
      top: 78px; } }
  @media screen and (max-width: 499px) {
    .sec-Opencampus .contents__box .image {
      width: 134px;
      height: 51px;
      top: 86px; } }

.sec-Opencampus .btn-box-oc {
  display: flex;
  justify-content: center;
  margin-top: 2.56223vw; }
  @media screen and (min-width: 1366px) {
    .sec-Opencampus .btn-box-oc {
      margin-top: 35px; } }
  @media screen and (max-width: 499px) {
    .sec-Opencampus .btn-box-oc {
      margin-top: 20px; } }

.sec-Opencampus .oc-btn {
  display: block;
  width: 20.4978vw;
  height: 5.12445vw;
  text-align: center;
  font-size: 1.1713vw;
  font-weight: 700;
  position: relative;
  padding-top: 1.31772vw;
  border: 2px solid #000;
  background: #fbed37; }
  .sec-Opencampus .oc-btn::after {
    position: absolute;
    bottom: 1.1713vw;
    right: 0;
    left: 0;
    margin: 0 auto;
    content: '';
    background: url("../../assets/img/home/oc_btn_arrow.svg") center center/cover;
    width: 2.12299vw;
    height: 0.43924vw; }
  .sec-Opencampus .oc-btn.choi {
    margin-left: 2.19619vw; }
  @media screen and (min-width: 1366px) {
    .sec-Opencampus .oc-btn {
      width: 280px;
      height: 70px;
      font-size: 16px;
      padding-top: 18px; }
      .sec-Opencampus .oc-btn::after {
        bottom: 16px;
        width: 29px;
        height: 6px; }
      .sec-Opencampus .oc-btn.choi {
        margin-left: 30px; } }
  @media screen and (max-width: 767px) {
    .sec-Opencampus .oc-btn {
      width: auto;
      min-width: 20.4978vw;
      padding-right: 0.73206vw;
      padding-left: 0.73206vw; } }
  @media screen and (max-width: 499px) {
    .sec-Opencampus .oc-btn {
      width: 163px;
      height: 85px;
      font-size: 12px;
      line-height: 1.66667;
      padding: 15px; }
      .sec-Opencampus .oc-btn::after {
        bottom: 10px;
        width: 29px;
        height: 6px; }
      .sec-Opencampus .oc-btn.choi {
        margin-left: 8px; } }

@media screen and (max-width: 499px) {
  .sec-HighSchool .Container {
    flex-flow: column; } }

.sec-HighSchool .Container .image {
  width: 50%;
  height: 100vh;
  display: block; }
  .sec-HighSchool .Container .image img {
    width: 100%;
    height: 100%; }
  @media screen and (max-width: 499px) {
    .sec-HighSchool .Container .image {
      width: 100%;
      height: 54.7226%; } }

.sec-HighSchool .Container .content {
  width: 50%;
  height: 100vh;
  background: #036EB8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  position: relative; }
  @media screen and (max-width: 499px) {
    .sec-HighSchool .Container .content {
      width: 100%;
      height: 45.2774%;
      padding-bottom: 18px; } }
  .sec-HighSchool .Container .content::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 2.19619vw 2.19619vw;
    border-color: transparent transparent rgba(0, 0, 0, 0.2) transparent;
    position: absolute;
    right: 1.0981vw;
    bottom: 1.0981vw; }
    @media screen and (min-width: 1366px) {
      .sec-HighSchool .Container .content::after {
        border-width: 0 0 30px 30px;
        right: 15px;
        bottom: 15px; } }
    @media screen and (max-width: 499px) {
      .sec-HighSchool .Container .content::after {
        border-width: 0 0 30px 30px;
        right: 15px;
        bottom: 15px; } }
  .sec-HighSchool .Container .content h3 {
    color: #fff;
    font-size: 3.80673vw;
    line-height: 5.85652vw;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.1em; }
    @media screen and (min-width: 1366px) {
      .sec-HighSchool .Container .content h3 {
        font-size: 52px;
        line-height: 80px; } }
    @media screen and (max-width: 499px) {
      .sec-HighSchool .Container .content h3 {
        font-size: 30px;
        line-height: 40px; } }
  .sec-HighSchool .Container .content .text {
    color: #fff;
    font-size: 1.31772vw;
    text-align: center;
    letter-spacing: 0.1em;
    margin-top: 1.83016vw; }
    @media screen and (min-width: 1366px) {
      .sec-HighSchool .Container .content .text {
        font-size: 18px;
        margin-top: 25px; } }
    @media screen and (max-width: 499px) {
      .sec-HighSchool .Container .content .text {
        font-size: 12px;
        margin-top: 12px; } }
  .sec-HighSchool .Container .content .icon-hat {
    width: 9.37042vw;
    height: 8.56515vw;
    margin-bottom: 2.19619vw; }
    @media screen and (min-width: 1366px) {
      .sec-HighSchool .Container .content .icon-hat {
        width: 128px;
        height: 117px;
        margin-bottom: 30px; } }
    @media screen and (max-width: 499px) {
      .sec-HighSchool .Container .content .icon-hat {
        width: 56px;
        height: 51px;
        margin-bottom: 18px; } }

.sec-YouTube .contents__box .inr {
  text-align: center;
  width: 82.72328vw;
  height: 41.80088vw;
  display: flex;
  padding-top: 2.19619vw; }
  @media screen and (min-width: 1366px) {
    .sec-YouTube .contents__box .inr {
      width: 1130px;
      height: 571px;
      padding-top: 30px; } }
  @media screen and (max-width: 499px) {
    .sec-YouTube .contents__box .inr {
      width: 100vw;
      height: auto;
      flex-flow: column;
      justify-content: center;
      padding-top: 0; } }

.sec-YouTube .contents__box .left {
  width: 38.79941vw;
  height: 100%; }
  @media screen and (min-width: 1366px) {
    .sec-YouTube .contents__box .left {
      width: 530px; } }
  @media screen and (max-width: 499px) {
    .sec-YouTube .contents__box .left {
      width: 215px;
      height: 232px;
      margin: 0 auto; } }

.sec-YouTube .contents__box .right {
  width: 43.92387vw;
  height: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  position: relative; }
  .sec-YouTube .contents__box .right::before {
    content: '';
    width: 43.48463vw 21.08346vw;
    background: url("../../assets/img/home/bgtxt_movie.svg") top 33% center/contain no-repeat;
    background-size: 43.48463vw 21.08346vw;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto; }
  @media screen and (min-width: 1366px) {
    .sec-YouTube .contents__box .right {
      width: 600px; }
      .sec-YouTube .contents__box .right::before {
        width: 594px 288px;
        background-size: 594px 288px; } }
  @media screen and (max-width: 499px) {
    .sec-YouTube .contents__box .right {
      padding-bottom: 0;
      width: 100%;
      height: 248px;
      padding-bottom: 0;
      margin-top: 17px; }
      .sec-YouTube .contents__box .right::before {
        width: 391px;
        height: 190px;
        background-size: 391px 190px;
        background: url("../../assets/img/home/bgtxt_movie.svg") center center/contain no-repeat;
        top: -1.7%;
        right: auto;
        bottom: auto;
        left: auto;
        margin: auto; } }

.sec-YouTube .contents__box .icon-yt {
  width: 5.85652vw;
  height: 4.17277vw;
  margin-bottom: 2.56223vw;
  z-index: 1; }
  @media screen and (min-width: 1366px) {
    .sec-YouTube .contents__box .icon-yt {
      width: 80px;
      height: 57px;
      margin-bottom: 35px; } }
  @media screen and (max-width: 499px) {
    .sec-YouTube .contents__box .icon-yt {
      width: 38px;
      height: 27px;
      margin-bottom: 8px; } }

.sec-YouTube .contents__box .tit {
  font-size: 3.80673vw;
  font-weight: bold;
  letter-spacing: 0.15em;
  position: relative;
  padding-bottom: 1.1713vw;
  margin-bottom: 1.83016vw;
  line-height: 5.85652vw; }
  .sec-YouTube .contents__box .tit::after {
    content: '';
    width: 2.41581vw;
    height: 0.21962vw;
    background: #036EB8;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto; }
  @media screen and (min-width: 1366px) {
    .sec-YouTube .contents__box .tit {
      font-size: 52px;
      padding-bottom: 16px;
      margin-bottom: 25px;
      line-height: 80px; }
      .sec-YouTube .contents__box .tit::after {
        width: 33px;
        height: 3px; } }
  @media screen and (max-width: 499px) {
    .sec-YouTube .contents__box .tit {
      font-size: 30px;
      line-height: 40px;
      padding-bottom: 8px;
      margin-bottom: 6px; }
      .sec-YouTube .contents__box .tit::after {
        width: 20px;
        height: 2px; } }

.sec-YouTube .contents__box .text {
  font-size: 1.31772vw;
  line-height: 1.88889;
  letter-spacing: 0.1em; }
  @media screen and (min-width: 1366px) {
    .sec-YouTube .contents__box .text {
      font-size: 16px; } }
  @media screen and (max-width: 499px) {
    .sec-YouTube .contents__box .text {
      font-size: 12px; } }

.sec-YouTube .contents__box .btn-box-youtube {
  margin-top: 3.66032vw; }
  @media screen and (min-width: 1366px) {
    .sec-YouTube .contents__box .btn-box-youtube {
      margin-top: 50px; } }
  @media screen and (max-width: 499px) {
    .sec-YouTube .contents__box .btn-box-youtube {
      margin-top: 20px; } }

.sec-Admission .top-wrap {
  background-color: #004182;
  position: relative; }
  .sec-Admission .top-wrap::before {
    content: '';
    position: absolute;
    background: url("../../assets/img/home/bg_admission_pc.svg") center center/contain;
    width: 100%;
    height: 27.89165vw; }
    @media screen and (max-width: 499px) {
      .sec-Admission .top-wrap::before {
        height: 89.33333vw;
        min-height: 335px;
        background: url("../../assets/img/home/bg_admission_sp.svg") center center/cover; } }
  .sec-Admission .top-wrap .content {
    position: relative; }
    @media screen and (max-width: 499px) {
      .sec-Admission .top-wrap .content {
        padding-top: 32px; } }
    .sec-Admission .top-wrap .content .tit {
      display: block; }
    .sec-Admission .top-wrap .content img {
      width: 39.53148vw;
      height: 15.73939vw; }
      @media screen and (min-width: 1366px) {
        .sec-Admission .top-wrap .content img {
          width: 540px;
          height: 215px; } }
      @media screen and (max-width: 499px) {
        .sec-Admission .top-wrap .content img {
          width: 282px;
          height: 90px; } }
    .sec-Admission .top-wrap .content .text {
      font-size: 1.31772vw;
      letter-spacing: 0.1em;
      color: #fff;
      margin-top: 2.19619vw; }
      @media screen and (min-width: 1366px) {
        .sec-Admission .top-wrap .content .text {
          font-size: 18px;
          margin-top: 30px; } }
      @media screen and (max-width: 499px) {
        .sec-Admission .top-wrap .content .text {
          font-size: 12px;
          margin-top: 10px;
          line-height: 2; } }

.sec-Admission .slide-wrap {
  background: url("../../assets/img/home/bg_admission_slide_pc.jpg") center center/cover; }
  @media screen and (max-width: 499px) {
    .sec-Admission .slide-wrap {
      background: url("../../assets/img/home/bg_admission_slide_sp.jpg") center center/cover; } }
  @media screen and (max-width: 499px) {
    .sec-Admission .slide-wrap .Container {
      padding-top: 8vw; } }
  .sec-Admission .slide-wrap .contents__box {
    width: 61.49341vw;
    height: 26.35432vw;
    position: relative;
    background-color: #fff;
    box-sizing: border-box;
    padding: 3.66032vw 3.66032vw 4.75842vw 4.75842vw;
    display: flex;
    justify-content: space-between; }
    .sec-Admission .slide-wrap .contents__box::before {
      content: '';
      width: 31.69839vw;
      height: 5.56369vw;
      background: #ccc;
      position: absolute;
      top: -7.17423vw;
      left: 0;
      right: 0;
      margin: 0 auto; }
    .sec-Admission .slide-wrap .contents__box.box-01::before {
      background: url("../../assets/img/home/txt_admission_box_01.svg"); }
    .sec-Admission .slide-wrap .contents__box.box-02::before {
      background: url("../../assets/img/home/txt_admission_box_02.svg"); }
    .sec-Admission .slide-wrap .contents__box.box-03::before {
      background: url("../../assets/img/home/txt_admission_box_03.svg"); }
    .sec-Admission .slide-wrap .contents__box.box-04::before {
      background: url("../../assets/img/home/txt_admission_box_04.svg"); }
    .sec-Admission .slide-wrap .contents__box.box-05::before {
      background: url("../../assets/img/home/txt_admission_box_05.svg"); }
    .sec-Admission .slide-wrap .contents__box.box-06::before {
      background: url("../../assets/img/home/txt_admission_box_06.svg"); }
    @media screen and (min-width: 1366px) {
      .sec-Admission .slide-wrap .contents__box {
        width: 840px;
        height: 360px;
        padding: 50px 50px 65px 65px; }
        .sec-Admission .slide-wrap .contents__box::before {
          width: 433px;
          height: 76px;
          top: -98px; } }
    @media screen and (max-width: 499px) {
      .sec-Admission .slide-wrap .contents__box {
        width: 290px;
        height: 390px;
        padding: 40px 30px 65px 30px;
        top: 3.74813vh; }
        .sec-Admission .slide-wrap .contents__box::before {
          width: 264px;
          height: 45px;
          top: -63px; } }
    @media screen and (max-width: 499px) {
      .sec-Admission .slide-wrap .contents__box .inr {
        width: 100%; } }
    .sec-Admission .slide-wrap .contents__box .subhead {
      color: #036EB8;
      font-size: 2.19619vw;
      line-height: 1;
      font-weight: bold;
      letter-spacing: 0.1em;
      text-align: left; }
      @media screen and (min-width: 1366px) {
        .sec-Admission .slide-wrap .contents__box .subhead {
          font-size: 30px; } }
      @media screen and (max-width: 499px) {
        .sec-Admission .slide-wrap .contents__box .subhead {
          font-size: 22px;
          line-height: 37px;
          min-height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center; } }
    .sec-Admission .slide-wrap .contents__box .list {
      width: 18.66764vw;
      margin-top: 1.75695vw; }
      @media screen and (min-width: 1366px) {
        .sec-Admission .slide-wrap .contents__box .list {
          width: 255px;
          margin-top: 24px; } }
      @media screen and (max-width: 499px) {
        .sec-Admission .slide-wrap .contents__box .list {
          width: 100%;
          margin-top: 10px; } }
      .sec-Admission .slide-wrap .contents__box .list li {
        border-bottom: 1px solid #dbdbdb;
        font-size: 1.1713vw;
        text-align: left;
        letter-spacing: 0.1em;
        line-height: 1;
        padding-top: 1.24451vw;
        padding-bottom: 1.24451vw;
        padding-left: 1.2em;
        /*@include _mq-down(sm) {
                        height: _vw(50, 375);
                        font-size: _vw(15, 375);
                        padding-top: _vw(17, 375);
                        padding-bottom: _vw(17, 375);
                    }*/ }
        @media screen and (min-width: 1366px) {
          .sec-Admission .slide-wrap .contents__box .list li {
            font-size: 16px;
            padding-top: 17px;
            padding-bottom: 17px; } }
        @media screen and (max-width: 499px) {
          .sec-Admission .slide-wrap .contents__box .list li {
            height: auto;
            font-size: 16px;
            padding-top: 17px;
            padding-bottom: 17px; } }
    .sec-Admission .slide-wrap .contents__box .etc {
      font-size: 1.1713vw;
      padding-top: 1.24451vw;
      text-align: left; }
      @media screen and (min-width: 1366px) {
        .sec-Admission .slide-wrap .contents__box .etc {
          font-size: 16px;
          padding-top: 17px; } }
      @media screen and (max-width: 499px) {
        .sec-Admission .slide-wrap .contents__box .etc {
          font-size: 16px;
          padding-top: 17px; } }
    .sec-Admission .slide-wrap .contents__box .image {
      width: 22.98682vw;
      height: 17.49634vw; }
      @media screen and (min-width: 1366px) {
        .sec-Admission .slide-wrap .contents__box .image {
          width: 314px;
          height: 239px; } }
  .sec-Admission .slide-wrap.slide02 .contents__box {
    flex-flow: column;
    align-items: center;
    padding: 3.66032vw 2.92826vw 4.75842vw 2.92826vw; }
    @media screen and (min-width: 1366px) {
      .sec-Admission .slide-wrap.slide02 .contents__box {
        padding: 50px 40px 65px 40px; } }
    @media screen and (max-width: 499px) {
      .sec-Admission .slide-wrap.slide02 .contents__box {
        padding: 40px 30px 65px 30px; } }
    .sec-Admission .slide-wrap.slide02 .contents__box .subhead {
      text-align: center; }
    .sec-Admission .slide-wrap.slide02 .contents__box .image {
      width: 48.31625vw;
      height: 11.27379vw; }
      @media screen and (min-width: 1366px) {
        .sec-Admission .slide-wrap.slide02 .contents__box .image {
          width: 660px;
          height: 154px; } }
      @media screen and (max-width: 499px) {
        .sec-Admission .slide-wrap.slide02 .contents__box .image {
          width: 200px;
          height: 169px;
          margin-top: 12px; } }
    .sec-Admission .slide-wrap.slide02 .contents__box .box-text {
      font-size: 1.31772vw;
      letter-spacing: 0.05em; }
      @media screen and (min-width: 1366px) {
        .sec-Admission .slide-wrap.slide02 .contents__box .box-text {
          font-size: 18px; } }
      @media screen and (max-width: 499px) {
        .sec-Admission .slide-wrap.slide02 .contents__box .box-text {
          font-size: 12px;
          letter-spacing: 0.1em;
          line-height: 2;
          margin-top: 14px; } }
